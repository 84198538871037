import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Svg,
  Path,
  Line,
  Rect,
} from "@react-pdf/renderer";

const BarChart = () => {
  // Chart dimensions
  const width = 400;
  const height = 200;
  const padding = 40;
  const data = [10, 20, 30, 40, 50];
  const maxValue = Math.max(...data);
  
  // Calculate scales
  const barWidth = (width - 2 * padding) / data.length - 10;
  const yScale = (height - 2 * padding) / maxValue;

  return (
    <View style={styles.chartContainer}>
      <Text style={styles.chartTitle}>Carbon Tracked</Text>
      <Svg width={width} height={height}>
        {/* Y-axis */}
        <Line
          x1={padding}
          y1={padding}
          x2={padding}
          y2={height - padding}
          stroke="#000000"
          strokeWidth={1}
        />
        
        {/* X-axis */}
        <Line
          x1={padding}
          y1={height - padding}
          x2={width - padding}
          y2={height - padding}
          stroke="#000000"
          strokeWidth={1}
        />

        {/* Bars */}
        {data.map((value, index) => (
          <Rect
            key={index}
            x={padding + index * (barWidth + 10)}
            y={height - padding - value * yScale}
            width={barWidth}
            height={value * yScale}
            fill="#00AED9"
          />
        ))}

        {/* X-axis labels */}
        {data.map((value, index) => (
          <Text
            key={index}
            x={padding + index * (barWidth + 10) + barWidth / 2}
            y={height - padding + 15}
            style={styles.axisLabel}
          >
            {value}
          </Text>
        ))}

        {/* Y-axis labels */}
        {[0, maxValue/2, maxValue].map((value, index) => (
          <Text
            key={index}
            x={padding - 25}
            y={height - padding - (value * yScale)}
            style={styles.axisLabel}
          >
            {value}
          </Text>
        ))}
      </Svg>
    </View>
  );
};

const EmissionsReport = () => {
      // Chart dimensions
      const width = 400;
      const height = 200;
      const padding = 40;
      const data = [10, 20, 30, 40, 50];
      const maxValue = Math.max(...data);
      
      // Calculate scales
      const barWidth = (width - 2 * padding) / data.length - 10;
      const yScale = (height - 2 * padding) / maxValue;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Emissions Monitoring Report Sample</Text>
          
          <Text style={styles.heading}>Executive Summary & Monitoring Framework</Text>
          
          {/* 1. Report Title and Overview */}
          <Text style={styles.subheading}>1. Report Title and Overview</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Title:</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>Emissions Monitoring and Quantification Report</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Organization Name:</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Reporting Period:</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>[Timeframe]</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Prepared By:</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>Netzence Sustainability Limited</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Date of issuance:</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
          </View>

          {/* 2. Executive Summary */}
          <Text style={styles.subheading}>2. Executive Summary</Text>
          <Text style={styles.paragraph}>
            Objective: Provide an overview of the monitored CO₂ emissions from direct activities under Scope 1, 
            including combustion sources, operational processes, and onsite fuel use.
          </Text>

          <View style={styles.listContainer}>
            <Text style={styles.listTitle}>Key Findings:</Text>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Total CO₂ emissions: [Total emissions in tonnes of CO₂e]</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Primary contributors: [key sources, e.g., stationary combustion, vehicle fleet]</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Notable trends: [insightful findings, e.g., 5% reduction compared to Q2 due to optimized fuel usage]</Text>
            </View>
          </View>

          {/* 3. Monitoring Framework */}
          <Text style={styles.subheading}>3. Monitoring Framework</Text>
          <View style={styles.listContainer}>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>
                Scope of Report: Focused exclusively on Scope 1 direct emissions resulting from [e.g., fuel combustion 
                in company-owned power source and power consumption form the facility]
              </Text>
            </View>
          </View>

          <View style={styles.listContainer}>
            <Text style={styles.listTitle}>Data Collection Process:</Text>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Utilized CloseCarbon IoT devices for real-time activity data monitoring</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Data aggregated from facility [Facility Name]</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Emissions Factors were derived from IPCC data base</Text>
            </View>
          </View>

          <View style={styles.listContainer}>
            <Text style={styles.listTitle}>Standards Applied:</Text>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>
                Emissions monitoring conducted in line with ISO 14064-1, ISO 14064-2 and IPCC Guidelines for GHG Inventories
              </Text>
            </View>
          </View>

          {/* Emissions Quantification & Analysis */}
          <Text style={styles.heading}>Emissions Quantification & Analysis</Text>

          {/* 5. CO₂ Emissions Summary */}
          <Text style={styles.subheading}>5. CO₂ Emissions Summary</Text>
          <Text style={styles.listTitle}>Emissions Data Table:</Text>
          <View style={styles.emissionsTable}>
            <View style={styles.tableHeader}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableHeaderCell}>Emission Source</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableHeaderCell}>Fuel Type</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableHeaderCell}>Fuel Consumed (liters)</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableHeaderCell}>CO₂ Emissions (tCO₂)</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>Stationary Combustion</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>Diesel</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>10,000 liters</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>26.3</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>-</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>-</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCell}>37.9</Text>
              </View>
            </View>
          </View>
          <Text style={styles.note}>Note: Emission factors were derived from [IPCC Guidelines]</Text>

          <Text style={styles.subheading}>6. Trend Analysis</Text>
          <View style={styles.listContainer}>
            <Text style={styles.listTitle}>Visualization:</Text>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Bar Chart: Emissions by source category (e.g stationary vs. mobile). Not Needed for 1 single emissions source</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Total emissions, Emissions per litre</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Line Graph: Monthly CO₂ emissions trend along with the power consumption or fuel consumption emissions trend</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Sample Images</Text>
            </View>
          </View>
          <BarChart />

          {/* 7. Key Observations */}
          <Text style={styles.subheading}>7. Key Observations</Text>
          <View style={styles.listContainer}>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Emission Hotspots: [E.g., Generators accounted for 70% of total Scope 1 emissions]</Text>
            </View>
          </View>

          <View style={styles.listContainer}>
            <Text style={styles.listTitle}>Operational Insights:</Text>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Inefficiencies in [e.g., older vehicle fleet] contributing to elevated emissions</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>High emissions during peak operational periods</Text>
            </View>
          </View>

          {/* 8. Recommendations for Reduction */}
          <Text style={styles.subheading}>8. Recommendations for Reduction</Text>
          <View style={styles.listContainer}>
            <Text style={styles.listTitle}>Transition to low-carbon alternatives:</Text>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Replace diesel generators with hybrid or renewable-powered systems</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Optimize fuel usage through preventive maintenance and route optimization for vehicles</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Improve energy efficiency in facilities</Text>
            </View>
          </View>

          {/* 9. Appendix */}
          <Text style={styles.subheading}>9. Appendix For GHG emissions Data Summary</Text>
          <Text style={styles.paragraph}>[Insert link to GHG emissions Data]</Text>

          {/* References */}
          <Text style={styles.subheading}>Possible Design Reference</Text>
          <View style={styles.listContainer}>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>Carbon Footprint Report 2022 Page 6-16</Text>
            </View>
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.listItemText}>2022-nrc-carbon-footprint-report.pdf Page 5-7</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#ffffff',
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 15,
  },
  subheading: {
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  paragraph: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'justify',
  },
  listContainer: {
    marginVertical: 5,
    paddingLeft: 10,
  },
  listTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginVertical: 5,
  },
  listItem: {
    flexDirection: 'row',
    marginVertical: 2,
  },
  bulletPoint: {
    width: 10,
    fontSize: 12,
  },
  listItemText: {
    flex: 1,
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginVertical: 10,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableColLarge: {
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
  },
  emissionsTable: {
    display: 'table',
    width: '100%',
    marginVertical: 10,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f0f0f0',
  },
  tableHeaderCell: {
    fontSize: 10,
    padding: 5,
    fontWeight: 'bold',
  },
  tableCol25: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  note: {
    fontSize: 10,
    fontStyle: 'italic',
    marginTop: 5,
  },
  chartContainer: {
    margin: 20,
    padding: 10,
    border: '1pt solid #ccc',
  },
  chartTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  axisLabel: {
    fontSize: 8,
    textAlign: 'center',
  },
});

export default EmissionsReport;