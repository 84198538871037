import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  matchPath,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { initializeApp } from "firebase/app";
import { getDatabase, push, ref } from "firebase/database";
import Login from "./pages/UserDashboard/Login";
import ThirdPartyLogin from "./pages/ThirdParty/Login";
import ThirdPartySignup from "./pages/ThirdParty/Signup";
import ForgotPassword from "./pages/UserDashboard/ForgotPassword";
import ThirdPartyForgotPassword from "./pages/ThirdParty/ForgotPassword";
import Signup from "./pages/UserDashboard/Signup";
import Dashboard from "./pages/UserDashboard/Home";
import ThirdPartyDashboard from "./pages/ThirdParty/Home";
import AdminDashboard from "./pages/AdminDashboard/Home";
import AdminCarbonCredit from "./pages/AdminDashboard/CarbonCredit";
import AdminLogin from "./pages/AdminDashboard/Login";
import Users from "./pages/AdminDashboard/Users";
import UsersDetails from "./pages/AdminDashboard/UserDetails";
import Validators from "./pages/AdminDashboard/Validators";
import ThirdPartyValidation from "./pages/ThirdParty/Validation";
import ThirdPartyVerification from "./pages/ThirdParty/Verification";
import ThirdPartycarbonCredit from "./pages/ThirdParty/CarbonCredit";
import VerifierHistory from "./pages/ThirdParty/History";
import ThirdPartyProfile from "./pages/ThirdParty/Profile";
import CarbonCredit from "./pages/UserDashboard/CarbonCredit";
import Validation from "./pages/UserDashboard/Validation";
import Verification from "./pages/UserDashboard/Verification";
import Profile from "./pages/UserDashboard/Profile";
import Settings from "./pages/UserDashboard/Settings";
import AdminIot from "./pages/AdminDashboard/Iot";
import { useSelector } from "react-redux";
import History from "./pages/AdminDashboard/History";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Iot from "./pages/ThirdParty/Iot";
import UsersIot from "./pages/UserDashboard/Iot";
import { useEffect, useState } from "react";
import Suggestion from "./pages/UserDashboard/Suggestion";
import ThirdPartySuggestion from "./pages/ThirdParty/Suggestion";
import { ethers } from "ethers";
import BasicGreenProject from "./Abi/BasicGreenProject.json";
import BasicInfrastructure from "./Abi/BasicInfrastructure.json";
import DonationCertificate from "./Abi/DonationCertificate.json";
import GovernanceCarbonMonitoring from "./Abi/GovernanceCarbonMonitoring.json";
import GovernanceGreenProject from "./Abi/GovernanceGreenProject.json";
import GovernanceInfrastructure from "./Abi/GovernanceInfrastructureChange.json";
import Demo from "./pages/shared/Demo";
import Solutions from "./pages/shared/Solutions";
import About from "./pages/shared/About";
import Blogs from "./pages/shared/Blogs";
import Blog from "./pages/shared/Blog";
import Resources from "./pages/shared/Resources";
import Partners from "./pages/shared/Partners";
import PrivacyPolicy from "./pages/shared/legal/PrivacyPolicy";
import TermsAndConditions from "./pages/shared/legal/TermsAndConditions";
import Monitoring from "./pages/shared/projects/Monitoring";
import Infrastructure from "./pages/shared/projects/Infrastructure";
import Green from "./pages/shared/projects/Green";
import NotFound from "./pages/shared/NotFound";
import Glossary from "./pages/shared/Glossary";
import NetworkError from "./pages/shared/NetworkError";
import IotDetails from "./components/UserDashboard/IotDetails";
import ThirdPartyIotDetails from "./components/ThirdPartyDashboard/IotDetails";
import CreateBlog from "./pages/AdminDashboard/WebsiteDashboard/CreateBlog";
import BlogList from "./pages/AdminDashboard/WebsiteDashboard/BlogList";
import WebsiteSettings from "./pages/AdminDashboard/WebsiteDashboard/Settings";
import SupportHub from "./pages/AdminDashboard/WebsiteDashboard/SupportHub";
import SendDemoMail from "./pages/AdminDashboard/WebsiteDashboard/SendDemoMail";
import UserMobileMenu from "./components/UserDashboard/MobileMenu";
import ThirdPartyMobileMenu from "./components/ThirdPartyDashboard/MobileMenu";
import AdminMobileMenu from "./components/AdminDashboard/MobileMenu";
import AdminWebsiteMobileMenu from "./components/AdminDashboard/WebsiteDashboard/MobileMenu";
import TemplateSubmission from "./pages/AdminDashboard/WebsiteDashboard/TemplateSubmission";
import UserResources from "./components/UserDashboard/Resources";
import Resource from "./components/UserDashboard/Resource";
import TwoFa from "./components/shared/TwoFa";
import AccountSettings from "./pages/AdminDashboard/AccountSettings";
import AccountManagementSettings from "./pages/AdminDashboard/AccountManagementSettings";
import PasswordSettings from "./pages/AdminDashboard/PasswordSettings";
import TestPdf from "./pages/ThirdParty/TestPdf";
import Basic from "./pages/shared/Basic";
import Governance from "./pages/shared/Governance";
import Verifiers from "./pages/shared/Verifiers";
import Ndc from "./pages/UserDashboard/Ndc";
import NdcList from "./pages/UserDashboard/NdcList";
import Scheduling from "./pages/AdminDashboard/Scheduling";
import { NdcRequests } from "./pages/AdminDashboard/NdcRequests";
import AdminNdcList from "./pages/AdminDashboard/AdminNdcList";
import ChatApp from "./pages/Chat";
import Donate from "./pages/Donation/Donate";
import DonationPool from "./pages/AdminDashboard/DonationPool";
import LiquidityPool from "./pages/AdminDashboard/LiquidityPool";
import UpdateFirmware from "./pages/IoT/UpdateFirmware";
import BasicOnboardingTemplates from "./pages/UserDashboard/BasicOnboardingTemplates";
import GovernanceOnboardingTemplates from "./pages/UserDashboard/GovernanceOnboardingTemplates";
import VerifiersOnboardingTemplates from "./pages/UserDashboard/VerifiersOnboardingTemplates";
import Kyc from "./components/UserDashboard/Kyc";
import Evaluation from "./pages/ThirdParty/Evaluation";
import SlaAgreement from "./pages/ThirdParty/SlaAgreement";
import DonationPdf from "./pages/ThirdParty/DonationPdf";
import SlaDocument from "./pages/shared/legal/Sla";
import ReactGA from 'react-ga4';
import { usePageTracking } from "./utils/pageTracking";
import JiraaWidget from "./components/JiraWidget";
import Business from "./pages/shared/Business";
import BusinessOnboardingTemplates from "./pages/UserDashboard/BusinessOnboardingTemplates";
import BusinessPlanPayment from "./pages/shared/BusinessPlanPayment";
import EmissionReport from "./pages/ThirdParty/EmissionReport";
import Videos from "./pages/shared/Videos";


const firebaseConfig = {
  apiKey: "AIzaSyDZCXntRnN_rJNSI2qV2qYT1GAxg3Ublr8",
  authDomain: "netzence-97078.firebaseapp.com",
  databaseURL: "https://netzence-97078-default-rtdb.firebaseio.com",
  projectId: "netzence-97078",
  storageBucket: "netzence-97078.appspot.com",
  messagingSenderId: "571920347452",
  appId: "1:571920347452:web:5a1a4b0baab7ee9083ee39",
  measurementId: "G-4DGGXDTPDX",
};

initializeApp(firebaseConfig);

function App() {
  const location = useLocation();
  usePageTracking(); // Use the custom hook
  const kycExcludedPaths = ['/', '/update-firmware', "/donate", '/chat', "/template/basic", "/template/governance", "/template/verifier", "/test"
    ,"/demo", "/solutions", "/about", "/blogs", "/blog/:id", "/resources", "/partners", 
    "/privacy-policy", "/terms-and-conditions", "/projects/monitoring", 
    "/projects/infrastructure-change", "/projects/green", "/glossary/:index", "/plans/basic",
    "/plans/governance", "/plans/verifier" 
  ]; 

  const isPathExcluded = (pathname) => {
    return kycExcludedPaths.some(path => 
      matchPath(
        { path, end: true },
        pathname
      )
    );
  };

  /************* Event Listening & Logging *************/

  const REACT_APP_MUMBAI_URL = 'wss://polygon-mainnet.infura.io/v3/b455056c0c134223a663258c63b9f284';

  const provider = new ethers.WebSocketProvider("wss://polygon-mainnet.g.alchemy.com/v2/6Jhe8aayVn4pmEuLbrEJ1kNMW0431qrW");

  const db = getDatabase();

  /************* Event Listening & Logging *************/
  //basic green
  useEffect(() => {
    const basicGreenProjectContract = new ethers.Contract(
      "0xbf983A8094289120bc65D8d93D5974bEF078cB7f",
      BasicGreenProject.abi,
      provider
    );

    console.log('basicGreenProjectContract', basicGreenProjectContract);


    const basicGreenProjectPerformKYCEventListener = (
      addressToRecord,
      staticAttributes,
      organizationName,
      scenario,
      timestamp
    ) => {
      try {
        console.log("event happened! kyc deyyah");

        push(ref(db, "events"), {
          type: "Perform KYC",
          facilityType: "Green Project",
          addressToRecord,
          staticAttributes,
          organizationName,
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error("Error handling basic green PerformKYC event:", error);
      }
    };

    const basicGreenProjectPlantRecordedEventListener = (
      addressToRecord,
      dynamicAttributes,
      timestamp
    ) => {
      try {
        console.log("event happened! plat recorded");

        push(ref(db, "events"), {
          type: "Plant Recorded",
          facilityType: "Green Project",
          addressToRecord,
          dynamicAttributes,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling green PlantRecord event:", error);
      }
    };

    const basicGreenProjectValidateEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! plant validated");

        push(ref(db, "events"), {
          type: "Plant Validated",
          facilityType: "Green Project",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling basic green Validate event:", error);
      }
    };

    const basicGreenProjectVerifiedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! plant verified1212");

        push(ref(db, "events"), {
          type: "Plant Verified",
          facilityType: "Green Project",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling basic green Verified event:", error);
      }
    };

    const basicGreenProjectCarbonCreditEventListener = (
      addressToRecord,
      creditIssued,
      scenario,
      timestamp
    ) => {
      console.log("event happened! carbon credit issued");

      try {
        push(ref(db, "events"), {
          type: "Carbon Credits Transferred",
          facilityType: "Green Project",
          addressToRecord,
          creditIssued: ethers.formatEther(creditIssued.toString()),
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error("Error handling basic green CarbonCredit event:", error);
      }
    };

    // const basicGreenProjectPlantRejectedEventListener = (
    //   addressToRecord,
    //   timestamp
    // ) => {
    //   console.log("event happened!");

    //   try {
    //     push(ref(db, "events"), {
    //       type: "Plant Rejected",
    //       facilityType: "Green Project",
    //       addressToRecord,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error("Error handling basic green PlantRejected event:", error);
    //   }
    // };

    // const basicGreenProjectFeeSetEventListener = (
    //   validatorFees,
    //   ownerFees,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Fee Set",
    //       facilityType: "Green Project",
    //       validatorFees,
    //       ownerFees,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error("Error handling basic green FeeSet event:", error);
    //   }
    // };

    // const basicGreenProjectSetClosedCarbonTokenAddressEventListener = (
    //   addressToRecord,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Set Closed Carbon Token Address",
    //       facilityType: "Green Project",
    //       addressToRecord,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error handling basic green SetClosedCarbonTokenAddress event:",
    //       error
    //     );
    //   }
    // };

    // const basicGreenProjectGrantRoleListener = (
    //   role,
    //   account,
    //   status,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Grant Role",
    //       facilityType: "Green Project",
    //       role,
    //       account,
    //       status,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error("Error handling basic green GrantRole event:", error);
    //   }
    // };

    basicGreenProjectContract.on(
      "PerformKYC",
      basicGreenProjectPerformKYCEventListener
    );
    basicGreenProjectContract.on(
      "PlantRecorded",
      basicGreenProjectPlantRecordedEventListener
    );
    basicGreenProjectContract.on(
      "PlantValidated",
      basicGreenProjectValidateEventListener
    );
    basicGreenProjectContract.on(
      "PlantVerified",
      basicGreenProjectVerifiedEventListener
    );
    basicGreenProjectContract.on(
      "CarbonCreditsTransferred",
      basicGreenProjectCarbonCreditEventListener
    );
    // basicGreenProjectContract.on("PlantIsRejected", basicGreenProjectPlantRejectedEventListener);
    // basicGreenProjectContract.on(
    //   "FeeSet",
    //   basicGreenProjectFeeSetEventListener
    // );
    // basicGreenProjectContract.on(
    //   "SetClosedCarbonTokenAddress",
    //   basicGreenProjectSetClosedCarbonTokenAddressEventListener
    // );
    // basicGreenProjectContract.on(
    //   "GrantRole",
    //   basicGreenProjectGrantRoleListener
    // );

    return () => {
      basicGreenProjectContract.removeListener(
        "PerformKYC",
        basicGreenProjectPerformKYCEventListener
      );
      basicGreenProjectContract.removeListener(
        "PlantRecorded",
        basicGreenProjectPlantRecordedEventListener
      );
      basicGreenProjectContract.removeListener(
        "PlantValidated",
        basicGreenProjectValidateEventListener
      );
      basicGreenProjectContract.removeListener(
        "PlantVerified",
        basicGreenProjectVerifiedEventListener
      );
      basicGreenProjectContract.removeListener(
        "CarbonCreditsTransferred",
        basicGreenProjectCarbonCreditEventListener
      );
      // basicGreenProjectContract.removeListener(
      //   "PlantIsRejected",
      //   basicGreenProjectPlantRejectedEventListener
      // );
      // basicGreenProjectContract.removeListener(
      //   "FeeSet",
      //   basicGreenProjectFeeSetEventListener
      // );
      // basicGreenProjectContract.removeListener(
      //   "GrantRole",
      //   basicGreenProjectGrantRoleListener
      // );
    };
  }, []);

  //basic infra
  useEffect(() => {
    const basicInfrastructureChangeContract = new ethers.Contract(
      "0xF8c8DBAF8BaAd7A5171A3B8a4109D4716Fb8860a",
      BasicInfrastructure.abi,
      provider
    );

    const basicInfrastructureChangePerformKYCEventListener = (
      addressToRecord,
      staticAttributes,
      organizationName,
      scenario,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Perform KYC",
          facilityType: "infrastructure-change",
          addressToRecord,
          staticAttributes,
          organizationName,
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error("Error handling basic infra PerformKYC event:", error);
      }
    };

    const basicInfrastructureChangePlantRecordedEventListener = (
      addressToRecord,
      dynamicAttributes,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Recorded",
          facilityType: "infrastructure-change",
          addressToRecord,
          dynamicAttributes,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling basic infra PlantRecorded event:", error);
      }
    };

    const basicInfrastructureChangeValidateEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Validated",
          facilityType: "infrastructure-change",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling basic infra Validate event:", error);
      }
    };

    const basicInfrastructureChangeVerifiedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Verified",
          facilityType: "infrastructure-change",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling basic infra Verify event:", error);
      }
    };

    const basicInfrastructureChangeCarbonCreditEventListener = (
      addressToRecord,
      creditIssued,
      scenario,
      timestamp
    ) => {
      try {
        // console.log("carbon event happened!");

        push(ref(db, "events"), {
          type: "Carbon Credits Transferred",
          facilityType: "infrastructure-change",
          addressToRecord,
          creditIssued: ethers.formatEther(creditIssued.toString()),
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error("Error handling basic green CarbonCredit event:", error);
      }
    };

    const basicInfrastructureChangePlantRejectedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Rejected",
          facilityType: "infrastructure-change",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling basic infra PlantRejected event:", error);
      }
    };

    // const basicInfrastructureChangeFeeSetEventListener = (
    //   validatorFees,
    //   ownerFees,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Fee Set",
    //       facilityType: "infrastructure-change",
    //       validatorFees,
    //       ownerFees,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error("Error handling basic infra ChangeFeeSet event:", error);
    //   }
    // };

    // const basicInfrastructureChangeSetClosedCarbonTokenAddressEventListener = (
    //   addressToRecord,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Set Closed Carbon Token Address",
    //       facilityType: "infrastructure-change",
    //       addressToRecord,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error handling basic infra SetClosedCarbonTokenAddress event:",
    //       error
    //     );
    //   }
    // };

    // const basicInfrastructureChangeGrantRoleEventListener = (
    //   role,
    //   account,
    //   status,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Grant Role",
    //       facilityType: "infrastructure-change",
    //       role,
    //       account,
    //       status,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error handling basic infra GrantRoleEvent event:",
    //       error
    //     );
    //   }
    // };

    basicInfrastructureChangeContract.on(
      "PerformKYC",
      basicInfrastructureChangePerformKYCEventListener
    );
    basicInfrastructureChangeContract.on(
      "PlantRecorded",
      basicInfrastructureChangePlantRecordedEventListener
    );
    basicInfrastructureChangeContract.on(
      "PlantValidated",
      basicInfrastructureChangeValidateEventListener
    );
    basicInfrastructureChangeContract.on(
      "PlantVerified",
      basicInfrastructureChangeVerifiedEventListener
    );
    basicInfrastructureChangeContract.on(
      "CarbonCreditsTransferred",
      basicInfrastructureChangeCarbonCreditEventListener
    );
    // basicInfrastructureChangeContract.on(
    //   "PlantIsRejected",
    //   basicInfrastructureChangePlantRejectedEventListener
    // );
    // basicInfrastructureChangeContract.on(
    //   "FeeSet",
    //   basicInfrastructureChangeFeeSetEventListener
    // );

    // basicInfrastructureChangeContract.on(
    //   "SetClosedCarbonTokenAddress",
    //   basicInfrastructureChangeSetClosedCarbonTokenAddressEventListener
    // );
    // basicInfrastructureChangeContract.on(
    //   "GrantRole",
    //   basicInfrastructureChangeGrantRoleEventListener
    // );

    return () => {
      basicInfrastructureChangeContract.removeListener(
        "PerformKYC",
        basicInfrastructureChangePerformKYCEventListener
      );
      basicInfrastructureChangeContract.removeListener(
        "PlantRecorded",
        basicInfrastructureChangePlantRecordedEventListener
      );
      basicInfrastructureChangeContract.removeListener(
        "PlantValidated",
        basicInfrastructureChangeValidateEventListener
      );
      basicInfrastructureChangeContract.removeListener(
        "PlantVerified",
        basicInfrastructureChangeVerifiedEventListener
      );
      basicInfrastructureChangeContract.removeListener(
        "CarbonCreditsTransferred",
        basicInfrastructureChangeCarbonCreditEventListener
      );
      // basicInfrastructureChangeContract.removeListener(
      //   "PlantIsRejected",
      //   basicInfrastructureChangePlantRejectedEventListener
      // );

      // basicInfrastructureChangeContract.removeListener(
      //   "FeeSet",
      //   basicInfrastructureChangeFeeSetEventListener
      // );
      // basicInfrastructureChangeContract.removeListener(
      //   "GrantRole",
      //   basicInfrastructureChangeGrantRoleEventListener
      // );
    };
  }, []);

  //governance green
  useEffect(() => {
    const governanceGreenProjectContract = new ethers.Contract(
      "0xeeEB6faede309C4aB989f67F7c77815E1298d9A8",
      GovernanceGreenProject.abi,
      provider
    );

    const governanceGreenProjectPerformKYCEventListener = (
      addressToRecord,
      staticAttributes,
      organizationName,
      scenario,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Perform KYC",
          facilityType: "Green Project",
          addressToRecord,
          staticAttributes,
          organizationName,
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error(
          "Error handling governance green PerformKYC event:",
          error
        );
      }
    };

    const governanceGreenProjectPlantRecordedEventListener = (
      addressToRecord,
      dynamicAttributes,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Recorded",
          facilityType: "Green Project",
          addressToRecord,
          dynamicAttributes,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance green PlantRecorded event:",
          error
        );
      }
    };

    const governanceGreenProjectValidateEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! plant validated");

        push(ref(db, "events"), {
          type: "Plant Validated",
          facilityType: "Green Project",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling governance green Validate event:", error);
      }
    };

    const governanceGreenProjectVerifiedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! plant verified1212");

        push(ref(db, "events"), {
          type: "Plant Verified",
          facilityType: "Green Project",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling governance green Verified event:", error);
      }
    };

    const governanceGreenProjectCarbonCreditEventListener = (
      addressToRecord,
      creditIssued,
      scenario,
      timestamp
    ) => {
      try {
        console.log("event happened! carbon credit issued");

        push(ref(db, "events"), {
          type: "Carbon Credits Transferred",
          facilityType: "Green Project",
          addressToRecord,
          creditIssued: ethers.formatEther(creditIssued.toString()),
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error(
          "Error handling governance green CarbonCredit event:",
          error
        );
      }
    };

    const governanceGreenProjectPlantRejectedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Rejected",
          facilityType: "Green Project",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance green PlantRejected event:",
          error
        );
      }
    };

    // const governanceGreenProjectFeeSetEventListener = (
    //   validatorFees,
    //   ownerFees,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Fee Set",
    //       facilityType: "Green Project",
    //       validatorFees,
    //       ownerFees,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error("Error handling governance green FeeSet event:", error);
    //   }
    // };

    // const governanceGreenProjectSetClosedCarbonTokenAddressEventListener = (
    //   addressToRecord,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Set Closed Carbon Token Address",
    //       facilityType: "Green Project",
    //       addressToRecord,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error handling governance green SetClosedCarbonTokenAddress event:",
    //       error
    //     );
    //   }
    // };

    const governanceGreenProjectGrantRoleListener = (
      role,
      account,
      status,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Grant Role",
          facilityType: "Green Project",
          role,
          account,
          status,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance green GrantRole event:",
          error
        );
      }
    };

    governanceGreenProjectContract.on(
      "PerformKYC",
      governanceGreenProjectPerformKYCEventListener
    );
    governanceGreenProjectContract.on(
      "PlantRecorded",
      governanceGreenProjectPlantRecordedEventListener
    );
    governanceGreenProjectContract.on(
      "PlantValidated",
      governanceGreenProjectValidateEventListener
    );
    governanceGreenProjectContract.on(
      "PlantVerified",
      governanceGreenProjectVerifiedEventListener
    );
    governanceGreenProjectContract.on(
      "CarbonCreditsTransferred",
      governanceGreenProjectCarbonCreditEventListener
    );
    // governanceGreenProjectContract.on("PlantIsRejected", governanceGreenProjectPlantRejectedEventListener);
    // governanceGreenProjectContract.on(
    //   "FeeSet",
    //   governanceGreenProjectFeeSetEventListener
    // );
    // governanceGreenProjectContract.on(
    //   "SetClosedCarbonTokenAddress",
    //   governanceGreenProjectSetClosedCarbonTokenAddressEventListener
    // );
    // governanceGreenProjectContract.on(
    //   "GrantRole",
    //   governanceGreenProjectGrantRoleListener
    // );
    /********* Governance Green Project Listeners *********/

    return () => {
      governanceGreenProjectContract.removeListener(
        "PerformKYC",
        governanceGreenProjectPerformKYCEventListener
      );
      governanceGreenProjectContract.removeListener(
        "PlantRecorded",
        governanceGreenProjectPlantRecordedEventListener
      );
      governanceGreenProjectContract.removeListener(
        "PlantValidated",
        governanceGreenProjectValidateEventListener
      );
      governanceGreenProjectContract.removeListener(
        "PlantVerified",
        governanceGreenProjectVerifiedEventListener
      );
      governanceGreenProjectContract.removeListener(
        "CarbonCreditsTransferred",
        governanceGreenProjectCarbonCreditEventListener
      );
      // governanceGreenProjectContract.removeListener(
      //   "PlantIsRejected",
      //   governanceGreenProjectPlantRejectedEventListener
      // );
      // governanceGreenProjectContract.removeListener(
      //   "FeeSet",
      //   governanceGreenProjectFeeSetEventListener
      // );
      // governanceGreenProjectContract.removeListener(
      //   "GrantRole",
      //   governanceGreenProjectGrantRoleListener
      // );
    };
  }, []);

  //governance infra
  useEffect(() => {
    const governanceInfrastructureChangeContract = new ethers.Contract(
      "0xc4220E90aB6144B08209A8f0e37E0504B25ff315",
      GovernanceInfrastructure.abi,
      provider
    );

    const governanceInfrastructureChangePerformKYCEventListener = (
      addressToRecord,
      staticAttributes,
      organizationName,
      scenario,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Perform KYC",
          facilityType: "infrastructure-change",
          addressToRecord,
          staticAttributes,
          organizationName,
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error(
          "Error handling governance infra PerformKYC event:",
          error
        );
      }
    };

    const governanceInfrastructureChangePlantRecordedEventListener = (
      addressToRecord,
      dynamicAttributes,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Recorded",
          facilityType: "infrastructure-change",
          addressToRecord,
          dynamicAttributes,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance infra PlantRecorded event:",
          error
        );
      }
    };

    const governanceInfrastructureChangeValidateEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Validated",
          facilityType: "infrastructure-change",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling governance infra Validate event:", error);
      }
    };

    const governanceInfrastructureChangeVerifiedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Verified",
          facilityType: "infrastructure-change",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error("Error handling governance infra Verified event:", error);
      }
    };

    const governanceInfrastructureChangeCarbonCreditEventListener = (
      addressToRecord,
      creditIssued,
      scenario,
      timestamp
    ) => {
      try {
        // console.log("carbon event happened!");

        push(ref(db, "events"), {
          type: "Carbon Credits Transferred",
          facilityType: "infrastructure-change",
          addressToRecord,
          creditIssued: ethers.formatEther(creditIssued.toString()),
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error(
          "Error handling governance infra CarbonCredit event:",
          error
        );
      }
    };

    const governanceInfrastructureChangePlantRejectedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Plant Rejected",
          facilityType: "infrastructure-change",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance infra PlantRejected event:",
          error
        );
      }
    };

    // const governanceInfrastructureChangeFeeSetEventListener = (
    //   validatorFees,
    //   ownerFees,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Fee Set",
    //       facilityType: "infrastructure-change",
    //       validatorFees,
    //       ownerFees,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error("Error handling governance infra FeeSet event:", error);
    //   }
    // };

    // const governanceInfrastructureChangeSetClosedCarbonTokenAddressEventListener =
    //   (addressToRecord, timestamp) => {
    //     try {
    //       console.log("event happened!");

    //       push(ref(db, "events"), {
    //         type: "Set Closed Carbon Token Address",
    //         facilityType: "infrastructure-change",
    //         addressToRecord,
    //         timestamp:
    //           ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //       });
    //     } catch (error) {
    //       console.error(
    //         "Error handling governance infra SetClosedCarbonTokenAddress event:",
    //         error
    //       );
    //     }
    //   };

    // const governanceInfrastructureChangeGrantRoleEventListener = (
    //   role,
    //   account,
    //   status,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Grant Role",
    //       facilityType: "infrastructure-change",
    //       role,
    //       account,
    //       status,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error handling governance infra GrantRole event:",
    //       error
    //     );
    //   }
    // };

    governanceInfrastructureChangeContract.on(
      "PerformKYC",
      governanceInfrastructureChangePerformKYCEventListener
    );
    governanceInfrastructureChangeContract.on(
      "PlantRecorded",
      governanceInfrastructureChangePlantRecordedEventListener
    );
    governanceInfrastructureChangeContract.on(
      "PlantValidated",
      governanceInfrastructureChangeValidateEventListener
    );
    governanceInfrastructureChangeContract.on(
      "PlantVerified",
      governanceInfrastructureChangeVerifiedEventListener
    );
    governanceInfrastructureChangeContract.on(
      "CarbonCreditsTransferred",
      governanceInfrastructureChangeCarbonCreditEventListener
    );
    // governanceInfrastructureChangeContract.on(
    //   "PlantIsRejected",
    //   governanceInfrastructureChangePlantRejectedEventListener
    // );
    // governanceInfrastructureChangeContract.on(
    //   "FeeSet",
    //   governanceInfrastructureChangeFeeSetEventListener
    // );

    // governanceInfrastructureChangeContract.on(
    //   "SetClosedCarbonTokenAddress",
    //   governanceInfrastructureChangeSetClosedCarbonTokenAddressEventListener
    // );
    // governanceInfrastructureChangeContract.on(
    //   "GrantRole",
    //   governanceInfrastructureChangeGrantRoleEventListener
    // );

    return () => {
      governanceInfrastructureChangeContract.removeListener(
        "PerformKYC",
        governanceInfrastructureChangePerformKYCEventListener
      );
      governanceInfrastructureChangeContract.removeListener(
        "PlantRecorded",
        governanceInfrastructureChangePlantRecordedEventListener
      );
      governanceInfrastructureChangeContract.removeListener(
        "PlantValidated",
        governanceInfrastructureChangeValidateEventListener
      );
      governanceInfrastructureChangeContract.removeListener(
        "PlantVerified",
        governanceInfrastructureChangeVerifiedEventListener
      );
      governanceInfrastructureChangeContract.removeListener(
        "CarbonCreditsTransferred",
        governanceInfrastructureChangeCarbonCreditEventListener
      );
      // governanceInfrastructureChangeContract.removeListener(
      //   "PlantIsRejected",
      //   governanceInfrastructureChangePlantRejectedEventListener
      // );

      // governanceInfrastructureChangeContract.removeListener(
      //   "FeeSet",
      //   governanceInfrastructureChangeFeeSetEventListener
      // );
      // governanceInfrastructureChangeContract.removeListener(
      //   "GrantRole",
      //   governanceInfrastructureChangeGrantRoleEventListener
      // );
    };
  }, []);

  //governance monitoring
  useEffect(() => {
    const governanceCarbonMonitoringContract = new ethers.Contract(
      "0xF7f008A1e5A827C326fA6284c97c59c3Fe660B06",
      GovernanceCarbonMonitoring.abi,
      provider
    );

    const governanceCarbonMonitoringPerformKYCEventListener = (
      addressToRecord,
      staticAttributes,
      organizationName,
      scenario,
      timestamp
    ) => {
      try {
        console.log("event happened!");

        push(ref(db, "events"), {
          type: "Perform KYC",
          facilityType: "Carbon Monitoring",
          addressToRecord,
          staticAttributes,
          organizationName,
          scenario,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
          read: false,
        });
      } catch (error) {
        console.error(
          "Error handling governance monitoring PerformKYC event:",
          error
        );
      }
    };

    const governanceCarbonMonitoringPlantRecordedEventListener = (
      addressToRecord,
      directEmissions,
      greenIndirectDynamicAttributes,
      timestamp
    ) => {
      try {
        console.log("event happened! carbon monitoring");

        push(ref(db, "events"), {
          type: "Plant Recorded",
          facilityType: "Carbon Monitoring",
          addressToRecord,
          directEmissions,
          greenIndirectDynamicAttributes,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance monitoring PlantRecorded event:",
          error
        );
      }
    };

    const governanceCarbonMonitoringValidateEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! carbon monitoring");

        push(ref(db, "events"), {
          type: "Plant Validated",
          facilityType: "Carbon Monitoring",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance monitoring Validate event:",
          error
        );
      }
    };

    const governanceCarbonMonitoringVerifiedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! carbon monitoring");

        push(ref(db, "events"), {
          type: "Plant Verified",
          facilityType: "Carbon Monitoring",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance monitoring Verified event:",
          error
        );
      }
    };

    const governanceCarbonMonitoringPlantRejectedEventListener = (
      addressToRecord,
      timestamp
    ) => {
      try {
        console.log("event happened! carbon monitoring");

        push(ref(db, "events"), {
          type: "Plant Rejected",
          facilityType: "Carbon Monitoring",
          addressToRecord,
          timestamp: timestamp?.toString() && ethers?.formatEther(timestamp?.toString()) * 1000000000000000000,
        });
      } catch (error) {
        console.error(
          "Error handling governance monitoring PlantRejected event:",
          error
        );
      }
    };

    // const governanceCarbonMonitoringGrantRoleEventListener = (
    //   role,
    //   account,
    //   status,
    //   timestamp
    // ) => {
    //   try {
    //     console.log("event happened!");

    //     push(ref(db, "events"), {
    //       type: "Grant Role",
    //       facilityType: "Carbon Monitoring",
    //       role,
    //       account,
    //       status,
    //       // timestamp:
    //       //   ethers.formatEther(timestamp.toString()) * 1000000000000000000,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error handling governance monitoring GrantRole event:",
    //       error
    //     );
    //   }
    // };

    governanceCarbonMonitoringContract.on(
      "PerformKYC",
      governanceCarbonMonitoringPerformKYCEventListener
    );
    governanceCarbonMonitoringContract.on(
      "PlantRecorded",
      governanceCarbonMonitoringPlantRecordedEventListener
    );
    // governanceCarbonMonitoringContract.on(
    //   "GrantRole",
    //   governanceCarbonMonitoringGrantRoleEventListener
    // );
    governanceCarbonMonitoringContract.on(
      "PlantValidated",
      governanceCarbonMonitoringValidateEventListener
    );
    governanceCarbonMonitoringContract.on(
      "PlantVerified",
      governanceCarbonMonitoringVerifiedEventListener
    );
    // governanceCarbonMonitoringContract.on(
    //   "PlantIsRejected",
    //   governanceCarbonMonitoringPlantRejectedEventListener
    // );

    return () => {
      governanceCarbonMonitoringContract.removeListener(
        "PerformKYC",
        governanceCarbonMonitoringPerformKYCEventListener
      );
      governanceCarbonMonitoringContract.removeListener(
        "PlantRecorded",
        governanceCarbonMonitoringPlantRecordedEventListener
      );
      // governanceCarbonMonitoringContract.removeListener(
      //   "GrantRole",
      //   governanceCarbonMonitoringGrantRoleEventListener
      // );
      governanceCarbonMonitoringContract.removeListener(
        "PlantValidated",
        governanceCarbonMonitoringValidateEventListener
      );
      governanceCarbonMonitoringContract.removeListener(
        "PlantVerified",
        governanceCarbonMonitoringVerifiedEventListener
      );
      // governanceCarbonMonitoringContract.removeListener(
      //   "PlantIsRejected",
      //   governanceCarbonMonitoringPlantRejectedEventListener
      // );
    };
  }, []);
  /************* Event Listening & Logging *************/

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = (e) => {
    if (e.target.classList.contains("help_modal_cover")) {
      setModalOpen(false);
    }
  };

  const handleSuggestion = () => {
    if (auth?.userInfo?.role === "user") {
      navigate("/suggestion");
      window.scrollTo(0, 0);
    } else if (auth?.userInfo?.role === "thirdParty") {
      navigate("/third-party/suggestion");
      window.scrollTo(0, 0);
    }
    setModalOpen(false);
  };
  // const location = useLocation();

  const [isOnline, setIsOnline] = useState(true);

  // useEffect(() => {
  //   console.log('gamewer');
  //   function handleStatusChange(status) {
  //     console.log("you look like metro teew much", status?.type === 'online');
  //     setIsOnline(status?.type === 'online');
  //     // if (status?.type === "online") {
  //     //   navigate(-1);
  //     // } else {
  //     //   navigate("/network-error");
  //     // }
  //   }

  //   window.addEventListener("online", handleStatusChange);
  //   window.addEventListener("offline", handleStatusChange);

  //   return () => {
  //     window.removeEventListener('online', handleStatusChange);
  //     window.removeEventListener('offline', handleStatusChange);
  //   }
  // }, [isOnline, navigate, location]);
  console.log(
    'pathname.includes("website")',
    pathname,
    pathname.includes("website")
  );


  const excludedPaths = [
    '/settings',              // Exact match
    '/profile',              // Exact match
    // /^\/admin\/.*/,          // Regex for all admin routes
    // /^\/dashboard\/private/   // Regex for private dashboard routes
  ];

  return (
    <div className="mega_container">
      {/* {isOnline ?  <> */}
      {auth.token && auth?.userInfo?.role === "user" && <UserMobileMenu />}
      {(auth.token && auth?.userInfo?.role === "thirdParty") ||
        (auth.token && auth?.userInfo?.role === "verifier" && (
          <ThirdPartyMobileMenu />
        ))}
      {auth.token &&
        auth?.userInfo?.role === "admin" &&
        pathname.includes("website") && <AdminWebsiteMobileMenu />}
      {auth.token && auth?.userInfo?.role === "admin" && <AdminMobileMenu />}
      {auth?.token && auth?.userInfo?.role === "user" && <TwoFa />}


      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route
          path="/"
          element={
            // auth.token && auth?.userInfo?.role === "user" ? (
            //   <Dashboard />
            // ) : auth.token && auth?.userInfo?.role === "thirdParty" || auth.token && auth?.userInfo?.role === "verifier" ? (
            //   <Navigate to="/third-party/dashboard" />
            // ) : auth.token && auth?.userInfo?.role === "admin" ? (
            //   <Navigate to="/admin/dashboard" />
            // ) : (
            <LandingPage />
            // )
          }
        />
        <Route
          path="/update-firmware"
          element={
            // auth.token && auth?.userInfo?.role === "user" ? (
            //   <Dashboard />
            // ) : auth.token && auth?.userInfo?.role === "thirdParty" || auth.token && auth?.userInfo?.role === "verifier" ? (
            //   <Navigate to="/third-party/dashboard" />
            // ) : auth.token && auth?.userInfo?.role === "admin" ? (
            //   <Navigate to="/admin/dashboard" />
            // ) : (
            <UpdateFirmware />
            // )
          }
        />
        <Route
          path="/donate"
          element={
            // auth.token && auth?.userInfo?.role === "user" ? (
            //   <Dashboard />
            // ) : auth.token && auth?.userInfo?.role === "thirdParty" || auth.token && auth?.userInfo?.role === "verifier" ? (
            //   <Navigate to="/third-party/dashboard" />
            // ) : auth.token && auth?.userInfo?.role === "admin" ? (
            //   <Navigate to="/admin/dashboard" />
            // ) : (
            <Donate />
            // )
          }
        />
        <Route
          path="/chat"
          element={
            // auth.token && auth?.userInfo?.role === "user" ? (
            //   <Dashboard />
            // ) : auth.token && auth?.userInfo?.role === "thirdParty" || auth.token && auth?.userInfo?.role === "verifier" ? (
            //   <Navigate to="/third-party/dashboard" />
            // ) : auth.token && auth?.userInfo?.role === "admin" ? (
            //   <Navigate to="/admin/dashboard" />
            // ) : (
            <ChatApp />
            // )
          }
        />
        <Route
          path="/template/basic"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <BasicOnboardingTemplates />
            )
          }
        />
        <Route
          path="/template/business"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <BusinessOnboardingTemplates />
            )
          }
        />
        <Route
          path="/template/governance"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <GovernanceOnboardingTemplates />
            )
          }
        />
        <Route
          path="/template/verifier"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <VerifiersOnboardingTemplates />
            )
          }
        />
        <Route path="/test" element={<TestPdf />} />
        {/* <Route path="/sla" element={<EmissionReport />} /> */}
        {/* <Route path="/sla" element={<SlaAgreement />} /> */}
        {/* <Route path="/sla" element={<SlaDocument />} />
        <Route path="/donateop" element={<DonationPdf />} /> */}
        <Route path="/demo" element={<Demo />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/projects/monitoring" element={<Monitoring />} />
        <Route
          path="/projects/infrastructure-change"
          element={<Infrastructure />}
        />
        <Route path="/projects/green" element={<Green />} />
        <Route path="/glossary/:index" element={<Glossary />} />
        <Route path="/plans/basic" element={<Basic />} />
        <Route path="/plans/governance" element={<Governance />} />
        <Route path="/plans/verifier" element={<Verifiers />} />
        <Route path="/plans/business" element={<Business />} />
        <Route path="/plans/business/pay" element={<BusinessPlanPayment />} />
        <Route
          path="/login"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/signup/:id/:stage"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Signup />
            )
          }
        />
        <Route
          path="/signup/:id"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Signup />
            )
          }
        />
        <Route
          path="/forgot-password/:email"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Dashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        <Route
          path="/carbon-credit"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <CarbonCredit />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {/* <Route
          path="/validation"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Validation />
            ) : auth.token && auth?.userInfo?.role === "thirdParty" || auth.token && auth?.userInfo?.role === "verifier" ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        /> */}
        <Route
          path="/verification"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Verification />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/profile"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Profile />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/profile/editable"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Profile />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Settings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Settings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user/resources"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <UserResources />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user/resource/:index"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Resource />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/iot"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <UsersIot />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/ndc"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Ndc />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/ndc-list"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <NdcList />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/iot/:id/:createdAt"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <IotDetails />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/suggestion"
          element={
            auth.token && auth?.userInfo?.role === "user" ? (
              <Suggestion />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/third-party/login"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyDashboard />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <ThirdPartyLogin />
            )
          }
        />
        <Route
          path="/third-party/signup"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyDashboard />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <ThirdPartySignup />
            )
          }
        />
        <Route
          path="/third-party/signup/:id/:role"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyDashboard />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <ThirdPartySignup />
            )
          }
        />
        <Route
          path="/third-party/signup/:id/:stage"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyDashboard />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <ThirdPartySignup />
            )
          }
        />
        <Route
          path="/third-party/forgot-password/:email"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyDashboard />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <ThirdPartyForgotPassword />
            )
          }
        />
        <Route
          path="/third-party/dashboard"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyDashboard />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
       
        <Route
          path="/admin/validation"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <ThirdPartyValidation />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/third-party/verification"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyVerification />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
        <Route
          path="/third-party/history"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <VerifierHistory />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
        <Route
          path="/third-party/carbon-credit-issuance"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartycarbonCredit />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
        <Route
          path="/third-party/profile"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyProfile />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
        <Route
          path="/third-party/iot"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Iot />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
        <Route
          path="/third-party/iot/:id/:createdAt"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartyIotDetails />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
        <Route
          path="/third-party/suggestion"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <ThirdPartySuggestion />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />
         <Route
          path="/third-party/evaluation"
          element={
            (auth.token && auth?.userInfo?.role === "thirdParty") ||
            (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Evaluation />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "admin" ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <Navigate to="/third-party/login" />
            )
          }
        />

        {/* Admin Dashboard Routes */}
        <Route
          path="/admin/login"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AdminDashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <AdminLogin />
            )
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AdminDashboard />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/carbon-credit"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AdminCarbonCredit />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/donation"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <DonationPool />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/liquidity-pool"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <LiquidityPool />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/users"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <Users />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/users/:id"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <UsersDetails />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/validators"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <Validators />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/history"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <History />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/account-settings"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AccountSettings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/account-management-settings"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AccountManagementSettings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/password-settings"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <PasswordSettings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/health-status"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AdminIot />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/iot-scheduling"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <Scheduling />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />

        <Route
          path="/admin/ndc-requests"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <NdcRequests />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/ndc-list"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <AdminNdcList />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        {/* Admin Dashboard Routes */}

        {/* Website Dashboard Routes */}

        <Route
          path="/admin/website/create-blog"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <CreateBlog />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />

        <Route
          path="/admin/website/blog-list"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <BlogList />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/website/settings"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <WebsiteSettings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/website/settings/:sub"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <WebsiteSettings />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/website/support-hub"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <SupportHub />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/website/send-demo/:email"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <SendDemoMail />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/website/support-hub/:id"
          element={
            auth.token && auth?.userInfo?.role === "admin" ? (
              <TemplateSubmission />
            ) : (auth.token && auth?.userInfo?.role === "thirdParty") ||
              (auth.token && auth?.userInfo?.role === "verifier") ? (
              <Navigate to="/third-party/dashboard" />
            ) : auth.token && auth?.userInfo?.role === "user" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/network-error" element={<NetworkError />} />
      </Routes>
      <ToastContainer position="bottom-right" />

      {!pathname.includes("suggestion") &&
        auth?.token &&
        auth?.userInfo?.role !== "admin" && (
          <div className="help">
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="help_icon pointer"
              onClick={handleModalOpen}
            >
              <g filter="url(#filter0_dd_961_6984)">
                <rect
                  x="4"
                  y="4"
                  width="48"
                  height="48"
                  rx="16"
                  fill="#FAFAFA"
                />
                <path
                  d="M27.868 41.875C28.8472 41.875 29.641 41.0812 29.641 40.102C29.641 39.1228 28.8472 38.329 27.868 38.329C26.8888 38.329 26.095 39.1228 26.095 40.102C26.095 41.0812 26.8888 41.875 27.868 41.875Z"
                  fill="url(#paint0_radial_961_6984)"
                />
                <path
                  d="M30.047 34.172H25.766C25.0756 34.172 24.516 34.7316 24.516 35.422V39.641C24.516 40.3314 25.0756 40.891 25.766 40.891H30.047C30.7373 40.891 31.297 40.3314 31.297 39.641V35.422C31.297 34.7316 30.7373 34.172 30.047 34.172Z"
                  fill="url(#paint1_linear_961_6984)"
                />
                <path
                  d="M30.047 34.172H25.766C25.0756 34.172 24.516 34.7316 24.516 35.422V39.641C24.516 40.3314 25.0756 40.891 25.766 40.891H30.047C30.7373 40.891 31.297 40.3314 31.297 39.641V35.422C31.297 34.7316 30.7373 34.172 30.047 34.172Z"
                  fill="url(#paint2_linear_961_6984)"
                />
                <path
                  d="M30.047 34.172H25.766C25.0756 34.172 24.516 34.7316 24.516 35.422V39.641C24.516 40.3314 25.0756 40.891 25.766 40.891H30.047C30.7373 40.891 31.297 40.3314 31.297 39.641V35.422C31.297 34.7316 30.7373 34.172 30.047 34.172Z"
                  fill="url(#paint3_radial_961_6984)"
                />
                <path
                  d="M30.047 34.172H25.766C25.0756 34.172 24.516 34.7316 24.516 35.422V39.641C24.516 40.3314 25.0756 40.891 25.766 40.891H30.047C30.7373 40.891 31.297 40.3314 31.297 39.641V35.422C31.297 34.7316 30.7373 34.172 30.047 34.172Z"
                  fill="url(#paint4_linear_961_6984)"
                />
                <g filter="url(#filter1_i_961_6984)">
                  <path
                    d="M27.767 34.969H27.821C28.377 34.966 29.655 34.945 30.168 34.852C30.718 34.752 30.789 34.477 30.887 34.047C31.489 31.393 32.499 30.373 33.175 29.691C33.279 29.586 33.375 29.488 33.461 29.395C33.754 29.073 34.008 28.789 34.201 28.57C35.3563 27.3434 36.128 25.8059 36.4215 24.1466C36.7149 22.4873 36.5171 20.7784 35.8526 19.23C35.188 17.6815 34.0855 16.361 32.6806 15.4306C31.2757 14.5002 29.6296 14.0005 27.9446 13.9929C26.2595 13.9853 24.609 14.4701 23.1958 15.3878C21.7825 16.3055 20.6682 17.6161 19.9897 19.1585C19.3112 20.7009 19.098 22.4079 19.3765 24.0698C19.6549 25.7317 20.4128 27.276 21.557 28.513C21.757 28.739 22.029 29.044 22.349 29.395C22.4414 29.4965 22.5367 29.5952 22.635 29.691C23.31 30.373 24.321 31.394 24.923 34.047C25.021 34.477 25.092 34.752 25.642 34.852C26.153 34.945 27.188 34.966 27.712 34.968L27.767 34.969Z"
                    fill="url(#paint5_radial_961_6984)"
                  />
                  <path
                    d="M27.767 34.969H27.821C28.377 34.966 29.655 34.945 30.168 34.852C30.718 34.752 30.789 34.477 30.887 34.047C31.489 31.393 32.499 30.373 33.175 29.691C33.279 29.586 33.375 29.488 33.461 29.395C33.754 29.073 34.008 28.789 34.201 28.57C35.3563 27.3434 36.128 25.8059 36.4215 24.1466C36.7149 22.4873 36.5171 20.7784 35.8526 19.23C35.188 17.6815 34.0855 16.361 32.6806 15.4306C31.2757 14.5002 29.6296 14.0005 27.9446 13.9929C26.2595 13.9853 24.609 14.4701 23.1958 15.3878C21.7825 16.3055 20.6682 17.6161 19.9897 19.1585C19.3112 20.7009 19.098 22.4079 19.3765 24.0698C19.6549 25.7317 20.4128 27.276 21.557 28.513C21.757 28.739 22.029 29.044 22.349 29.395C22.4414 29.4965 22.5367 29.5952 22.635 29.691C23.31 30.373 24.321 31.394 24.923 34.047C25.021 34.477 25.092 34.752 25.642 34.852C26.153 34.945 27.188 34.966 27.712 34.968L27.767 34.969Z"
                    fill="url(#paint6_radial_961_6984)"
                  />
                </g>
                <path
                  d="M29.462 22.281C31.3 22.281 32.79 20.791 32.79 18.953C32.79 17.115 31.3 15.625 29.462 15.625C27.624 15.625 26.134 17.115 26.134 18.953C26.134 20.791 27.624 22.281 29.462 22.281Z"
                  fill="url(#paint7_linear_961_6984)"
                />
                <g filter="url(#filter2_i_961_6984)">
                  <path
                    d="M27.4789 34.966C27.4439 32.794 27.2709 31.364 27.0069 30.368C26.7039 29.221 26.2789 28.648 25.8289 28.195L25.8239 28.189L25.8179 28.184C25.384 27.7736 25.0832 27.2424 24.9545 26.6592C24.8258 26.076 24.8751 25.4676 25.0959 24.9126C25.3168 24.3577 25.6991 23.8819 26.1934 23.5466C26.6877 23.2113 27.2712 23.0321 27.8684 23.0321C28.4657 23.0321 29.0492 23.2113 29.5434 23.5466C30.0377 23.8819 30.42 24.3577 30.6409 24.9126C30.8617 25.4676 30.911 26.076 30.7823 26.6592C30.6536 27.2424 30.3528 27.7736 29.9189 28.184L29.9129 28.189L29.9069 28.195C29.4659 28.641 29.0349 29.175 28.7279 30.313C28.4619 31.298 28.2879 32.733 28.2529 34.964C28.5549 34.959 28.9159 34.95 29.2539 34.934C29.2899 32.766 29.4599 31.438 29.6939 30.574C29.9459 29.637 30.2709 29.249 30.6119 28.904C31.1895 28.3553 31.5893 27.646 31.7597 26.8678C31.9301 26.0896 31.8633 25.2782 31.5679 24.5383C31.2725 23.7984 30.762 23.1641 30.1025 22.7173C29.4429 22.2705 28.6646 22.0316 27.8679 22.0316C27.0713 22.0316 26.2929 22.2705 25.6333 22.7173C24.9738 23.1641 24.4634 23.7984 24.1679 24.5383C23.8725 25.2782 23.8057 26.0896 23.9761 26.8678C24.1465 27.646 24.5463 28.3553 25.1239 28.904C25.4569 29.241 25.7859 29.662 26.0409 30.624C26.2729 31.503 26.4409 32.828 26.4779 34.934C26.8249 34.954 27.1899 34.962 27.4779 34.966H27.4789Z"
                    fill="#FFAD55"
                  />
                </g>
                <g filter="url(#filter3_f_961_6984)">
                  <path
                    d="M28.879 34.739C28.879 29.968 29.61 29.206 30.394 28.415C30.9006 27.9358 31.2518 27.3156 31.4021 26.6347C31.5524 25.9538 31.4949 25.2434 31.237 24.5955C30.9792 23.9476 30.5328 23.3919 29.9557 23.0005C29.3786 22.609 28.6973 22.3998 28 22.3998C27.3027 22.3998 26.6214 22.609 26.0443 23.0005C25.4672 23.3919 25.0208 23.9476 24.7629 24.5955C24.5051 25.2434 24.4476 25.9538 24.5979 26.6347C24.7482 27.3156 25.0994 27.9358 25.606 28.415C26.39 29.206 27.117 30.576 27.117 34.739"
                    stroke="url(#paint8_linear_961_6984)"
                    stroke-width="0.25"
                  />
                </g>
                <g filter="url(#filter4_f_961_6984)">
                  <path
                    d="M30.6303 36.1515L24.9585 37.0402C24.6666 37.0859 24.467 37.3597 24.5127 37.6516C24.5585 37.9435 24.8322 38.143 25.1241 38.0973L30.7959 37.2086C31.0878 37.1629 31.2874 36.8892 31.2416 36.5972C31.1959 36.3053 30.9222 36.1058 30.6303 36.1515Z"
                    fill="#7E6693"
                  />
                </g>
                <g filter="url(#filter5_f_961_6984)">
                  <path
                    d="M30.6303 38.5225L24.9585 39.4112C24.6666 39.4569 24.467 39.7307 24.5127 40.0226C24.5585 40.3145 24.8322 40.514 25.1241 40.4683L30.7959 39.5796C31.0878 39.5339 31.2874 39.2602 31.2416 38.9683C31.1959 38.6763 30.9222 38.4768 30.6303 38.5225Z"
                    fill="#7E6693"
                  />
                </g>
                <path
                  d="M31.3603 38.2604L24.2293 39.3777C23.9287 39.4248 23.7231 39.7067 23.7702 40.0074C23.8173 40.308 24.0992 40.5135 24.3999 40.4664L31.5309 39.3491C31.8315 39.302 32.037 39.0201 31.9899 38.7195C31.9428 38.4188 31.6609 38.2133 31.3603 38.2604Z"
                  fill="url(#paint9_linear_961_6984)"
                />
                <path
                  d="M31.3603 38.2604L24.2293 39.3777C23.9287 39.4248 23.7231 39.7067 23.7702 40.0074C23.8173 40.308 24.0992 40.5135 24.3999 40.4664L31.5309 39.3491C31.8315 39.302 32.037 39.0201 31.9899 38.7195C31.9428 38.4188 31.6609 38.2133 31.3603 38.2604Z"
                  fill="url(#paint10_linear_961_6984)"
                />
                <path
                  d="M31.3603 38.2604L24.2293 39.3777C23.9287 39.4248 23.7231 39.7067 23.7702 40.0074C23.8173 40.308 24.0992 40.5135 24.3999 40.4664L31.5309 39.3491C31.8315 39.302 32.037 39.0201 31.9899 38.7195C31.9428 38.4188 31.6609 38.2133 31.3603 38.2604Z"
                  fill="url(#paint11_linear_961_6984)"
                />
                <path
                  d="M31.3603 38.2604L24.2293 39.3777C23.9287 39.4248 23.7231 39.7067 23.7702 40.0074C23.8173 40.308 24.0992 40.5135 24.3999 40.4664L31.5309 39.3491C31.8315 39.302 32.037 39.0201 31.9899 38.7195C31.9428 38.4188 31.6609 38.2133 31.3603 38.2604Z"
                  fill="url(#paint12_radial_961_6984)"
                />
                <path
                  d="M31.3603 35.8834L24.2293 37.0007C23.9287 37.0478 23.7231 37.3297 23.7702 37.6304C23.8173 37.931 24.0992 38.1365 24.3999 38.0894L31.5309 36.9721C31.8315 36.925 32.037 36.6431 31.9899 36.3424C31.9428 36.0418 31.6609 35.8363 31.3603 35.8834Z"
                  fill="url(#paint13_linear_961_6984)"
                />
                <path
                  d="M31.3603 35.8834L24.2293 37.0007C23.9287 37.0478 23.7231 37.3297 23.7702 37.6304C23.8173 37.931 24.0992 38.1365 24.3999 38.0894L31.5309 36.9721C31.8315 36.925 32.037 36.6431 31.9899 36.3424C31.9428 36.0418 31.6609 35.8363 31.3603 35.8834Z"
                  fill="url(#paint14_linear_961_6984)"
                />
                <path
                  d="M31.3603 35.8834L24.2293 37.0007C23.9287 37.0478 23.7231 37.3297 23.7702 37.6304C23.8173 37.931 24.0992 38.1365 24.3999 38.0894L31.5309 36.9721C31.8315 36.925 32.037 36.6431 31.9899 36.3424C31.9428 36.0418 31.6609 35.8363 31.3603 35.8834Z"
                  fill="url(#paint15_linear_961_6984)"
                />
                <path
                  d="M31.3603 35.8834L24.2293 37.0007C23.9287 37.0478 23.7231 37.3297 23.7702 37.6304C23.8173 37.931 24.0992 38.1365 24.3999 38.0894L31.5309 36.9721C31.8315 36.925 32.037 36.6431 31.9899 36.3424C31.9428 36.0418 31.6609 35.8363 31.3603 35.8834Z"
                  fill="url(#paint16_radial_961_6984)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_dd_961_6984"
                  x="0"
                  y="0"
                  width="60"
                  height="60"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_961_6984"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_961_6984"
                    result="effect2_dropShadow_961_6984"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_961_6984"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_i_961_6984"
                  x="19.2576"
                  y="13.9928"
                  width="17.546"
                  height="21.1262"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.25" dy="0.15" />
                  <feGaussianBlur stdDeviation="0.5" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.992157 0 0 0 0 0.666667 0 0 0 0 0.309804 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_961_6984"
                  />
                </filter>
                <filter
                  id="filter2_i_961_6984"
                  x="23.8839"
                  y="22.0316"
                  width="8.21802"
                  height="12.9344"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.25" />
                  <feGaussianBlur stdDeviation="0.25" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.572549 0 0 0 0 0.254902 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_961_6984"
                  />
                </filter>
                <filter
                  id="filter3_f_961_6984"
                  x="24.141"
                  y="22.0248"
                  width="7.71802"
                  height="12.9642"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="0.125"
                    result="effect1_foregroundBlur_961_6984"
                  />
                </filter>
                <filter
                  id="filter4_f_961_6984"
                  x="24.2562"
                  y="35.895"
                  width="7.24194"
                  height="2.45883"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="0.125"
                    result="effect1_foregroundBlur_961_6984"
                  />
                </filter>
                <filter
                  id="filter5_f_961_6984"
                  x="24.2562"
                  y="38.266"
                  width="7.24194"
                  height="2.45883"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="0.125"
                    result="effect1_foregroundBlur_961_6984"
                  />
                </filter>
                <radialGradient
                  id="paint0_radial_961_6984"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(28.337 41.11) rotate(96.6541) scale(0.943858 2.18614)"
                >
                  <stop offset="0.268" stop-color="#AC90C6" />
                  <stop offset="1" stop-color="#8E6AAA" />
                </radialGradient>
                <linearGradient
                  id="paint1_linear_961_6984"
                  x1="31.297"
                  y1="37.802"
                  x2="24.516"
                  y2="37.802"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#CDC2D1" />
                  <stop offset="1" stop-color="#947C8D" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_961_6984"
                  x1="24.181"
                  y1="38.313"
                  x2="25.837"
                  y2="38.313"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8A7B88" />
                  <stop offset="1" stop-color="#8A7B88" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                  id="paint3_radial_961_6984"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(29.305 34.64) rotate(88.6357) scale(0.824874 3.67242)"
                >
                  <stop offset="0.229" stop-color="#EDE2EB" />
                  <stop offset="1" stop-color="#C8BCCB" stop-opacity="0" />
                </radialGradient>
                <linearGradient
                  id="paint4_linear_961_6984"
                  x1="29.462"
                  y1="40.891"
                  x2="29.462"
                  y2="39.625"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#7E5F9E" />
                  <stop offset="1" stop-color="#7E5F9E" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                  id="paint5_radial_961_6984"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(31.5559 20.1261) rotate(138.066) scale(9.91369 8.17473)"
                >
                  <stop offset="0.286" stop-color="#FFEC87" />
                  <stop offset="1" stop-color="#FFCD4B" />
                </radialGradient>
                <radialGradient
                  id="paint6_radial_961_6984"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(32.493 25.438) rotate(-147.604) scale(14.5819 14.0258)"
                >
                  <stop offset="0.622" stop-color="#FFB239" stop-opacity="0" />
                  <stop offset="1" stop-color="#FFB239" />
                </radialGradient>
                <linearGradient
                  id="paint7_linear_961_6984"
                  x1="28.298"
                  y1="20.027"
                  x2="31.72"
                  y2="17.361"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="0.594" stop-color="white" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_961_6984"
                  x1="31.72"
                  y1="22.997"
                  x2="27.714"
                  y2="32.54"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFBF5C" />
                  <stop offset="1" stop-color="#FFBF5C" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_961_6984"
                  x1="27.6964"
                  y1="38.8436"
                  x2="27.9625"
                  y2="40.0317"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#B1969A" />
                  <stop offset="1" stop-color="#DAD2E2" />
                </linearGradient>
                <linearGradient
                  id="paint10_linear_961_6984"
                  x1="28.8213"
                  y1="39.7504"
                  x2="28.6969"
                  y2="38.9692"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8A68A9" />
                  <stop offset="1" stop-color="#DEC2E3" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint11_linear_961_6984"
                  x1="23.6846"
                  y1="40.0937"
                  x2="26.4151"
                  y2="39.516"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#958387" />
                  <stop offset="1" stop-color="#958387" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                  id="paint12_radial_961_6984"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(30.5552 38.7681) rotate(81.4165) scale(0.837514 4.95711)"
                >
                  <stop offset="0.127" stop-color="#FCF0FC" />
                  <stop offset="1" stop-color="#E5C9E3" stop-opacity="0" />
                </radialGradient>
                <linearGradient
                  id="paint13_linear_961_6984"
                  x1="27.6966"
                  y1="36.4676"
                  x2="27.9625"
                  y2="37.6547"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#B1969A" />
                  <stop offset="1" stop-color="#DAD2E2" />
                </linearGradient>
                <linearGradient
                  id="paint14_linear_961_6984"
                  x1="28.8213"
                  y1="37.3734"
                  x2="28.6969"
                  y2="36.5922"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8A68A9" />
                  <stop offset="1" stop-color="#DEC2E3" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint15_linear_961_6984"
                  x1="23.6847"
                  y1="37.7176"
                  x2="26.4151"
                  y2="37.139"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#958387" />
                  <stop offset="1" stop-color="#958387" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                  id="paint16_radial_961_6984"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(30.5552 36.3911) rotate(81.4165) scale(0.837513 4.95709)"
                >
                  <stop offset="0.127" stop-color="#FCF0FC" />
                  <stop offset="1" stop-color="#E5C9E3" stop-opacity="0" />
                </radialGradient>
              </defs>
            </svg>

            {modalOpen && (
              <div className="help_modal">
                <svg
                  width="49"
                  height="48"
                  viewBox="0 0 49 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.302 44.8125C25.7708 44.8125 26.9615 43.6218 26.9615 42.153C26.9615 40.6842 25.7708 39.4935 24.302 39.4935C22.8332 39.4935 21.6425 40.6842 21.6425 42.153C21.6425 43.6218 22.8332 44.8125 24.302 44.8125Z"
                    fill="url(#paint0_radial_970_2067)"
                  />
                  <path
                    d="M27.5705 33.2579H21.149C20.1135 33.2579 19.274 34.0974 19.274 35.1329V41.4614C19.274 42.497 20.1135 43.3364 21.149 43.3364H27.5705C28.6061 43.3364 29.4455 42.497 29.4455 41.4614V35.1329C29.4455 34.0974 28.6061 33.2579 27.5705 33.2579Z"
                    fill="url(#paint1_linear_970_2067)"
                  />
                  <path
                    d="M27.5705 33.2579H21.149C20.1135 33.2579 19.274 34.0974 19.274 35.1329V41.4614C19.274 42.497 20.1135 43.3364 21.149 43.3364H27.5705C28.6061 43.3364 29.4455 42.497 29.4455 41.4614V35.1329C29.4455 34.0974 28.6061 33.2579 27.5705 33.2579Z"
                    fill="url(#paint2_linear_970_2067)"
                  />
                  <path
                    d="M27.5705 33.2579H21.149C20.1135 33.2579 19.274 34.0974 19.274 35.1329V41.4614C19.274 42.497 20.1135 43.3364 21.149 43.3364H27.5705C28.6061 43.3364 29.4455 42.497 29.4455 41.4614V35.1329C29.4455 34.0974 28.6061 33.2579 27.5705 33.2579Z"
                    fill="url(#paint3_radial_970_2067)"
                  />
                  <path
                    d="M27.5705 33.2579H21.149C20.1135 33.2579 19.274 34.0974 19.274 35.1329V41.4614C19.274 42.497 20.1135 43.3364 21.149 43.3364H27.5705C28.6061 43.3364 29.4455 42.497 29.4455 41.4614V35.1329C29.4455 34.0974 28.6061 33.2579 27.5705 33.2579Z"
                    fill="url(#paint4_linear_970_2067)"
                  />
                  <g filter="url(#filter0_i_970_2067)">
                    <path
                      d="M24.1505 34.4535H24.2315C25.0655 34.449 26.9825 34.4175 27.752 34.278C28.577 34.128 28.6835 33.7155 28.8305 33.0705C29.7335 29.0895 31.2485 27.5595 32.2625 26.5365C32.4185 26.379 32.5625 26.232 32.6915 26.0925C33.131 25.6095 33.512 25.1835 33.8015 24.855C35.5345 23.015 36.6921 20.7089 37.1323 18.2199C37.5724 15.7309 37.2758 13.1676 36.2789 10.845C35.282 8.52227 33.6283 6.54142 31.5209 5.14582C29.4136 3.75022 26.9444 3.00066 24.4169 2.98927C21.8894 2.97787 19.4135 3.70513 17.2937 5.08168C15.1739 6.45823 13.5023 8.42409 12.4846 10.7377C11.4668 13.0513 11.1471 15.6118 11.5647 18.1046C11.9824 20.5975 13.1192 22.914 14.8355 24.7695C15.1355 25.1085 15.5435 25.566 16.0235 26.0925C16.1621 26.2447 16.3052 26.3928 16.4525 26.5365C17.465 27.5595 18.9815 29.091 19.8845 33.0705C20.0315 33.7155 20.138 34.128 20.963 34.278C21.7295 34.4175 23.282 34.449 24.068 34.452L24.1505 34.4535Z"
                      fill="url(#paint5_radial_970_2067)"
                    />
                    <path
                      d="M24.1505 34.4535H24.2315C25.0655 34.449 26.9825 34.4175 27.752 34.278C28.577 34.128 28.6835 33.7155 28.8305 33.0705C29.7335 29.0895 31.2485 27.5595 32.2625 26.5365C32.4185 26.379 32.5625 26.232 32.6915 26.0925C33.131 25.6095 33.512 25.1835 33.8015 24.855C35.5345 23.015 36.6921 20.7089 37.1323 18.2199C37.5724 15.7309 37.2758 13.1676 36.2789 10.845C35.282 8.52227 33.6283 6.54142 31.5209 5.14582C29.4136 3.75022 26.9444 3.00066 24.4169 2.98927C21.8894 2.97787 19.4135 3.70513 17.2937 5.08168C15.1739 6.45823 13.5023 8.42409 12.4846 10.7377C11.4668 13.0513 11.1471 15.6118 11.5647 18.1046C11.9824 20.5975 13.1192 22.914 14.8355 24.7695C15.1355 25.1085 15.5435 25.566 16.0235 26.0925C16.1621 26.2447 16.3052 26.3928 16.4525 26.5365C17.465 27.5595 18.9815 29.091 19.8845 33.0705C20.0315 33.7155 20.138 34.128 20.963 34.278C21.7295 34.4175 23.282 34.449 24.068 34.452L24.1505 34.4535Z"
                      fill="url(#paint6_radial_970_2067)"
                    />
                  </g>
                  <path
                    d="M26.693 15.4215C29.4501 15.4215 31.6851 13.1865 31.6851 10.4295C31.6851 7.67249 29.4501 5.4375 26.693 5.4375C23.936 5.4375 21.701 7.67249 21.701 10.4295C21.701 13.1865 23.936 15.4215 26.693 15.4215Z"
                    fill="url(#paint7_linear_970_2067)"
                  />
                  <g filter="url(#filter1_i_970_2067)">
                    <path
                      d="M23.7185 34.4491C23.666 31.1911 23.4065 29.0461 23.0105 27.5521C22.556 25.8316 21.9185 24.9721 21.2435 24.2926L21.236 24.2836L21.227 24.2761C20.5761 23.6604 20.125 22.8637 19.9319 21.9889C19.7388 21.114 19.8127 20.2014 20.144 19.369C20.4753 18.5367 21.0488 17.8228 21.7902 17.32C22.5316 16.8171 23.4069 16.5482 24.3027 16.5482C25.1986 16.5482 26.0739 16.8171 26.8153 17.32C27.5567 17.8228 28.1302 18.5367 28.4614 19.369C28.7927 20.2014 28.8666 21.114 28.6736 21.9889C28.4805 22.8637 28.0293 23.6604 27.3785 24.2761L27.3695 24.2836L27.3605 24.2926C26.699 24.9616 26.0525 25.7626 25.592 27.4696C25.193 28.9471 24.932 31.0996 24.8795 34.4461C25.3325 34.4386 25.874 34.4251 26.381 34.4011C26.435 31.1491 26.69 29.1571 27.041 27.861C27.419 26.4556 27.9065 25.8736 28.418 25.3561C29.2843 24.533 29.8841 23.4691 30.1397 22.3018C30.3953 21.1344 30.2951 19.9173 29.8519 18.8075C29.4088 17.6977 28.6432 16.7462 27.6538 16.076C26.6645 15.4057 25.497 15.0475 24.302 15.0475C23.107 15.0475 21.9395 15.4057 20.9501 16.076C19.9608 16.7462 19.1952 17.6977 18.752 18.8075C18.3089 19.9173 18.2087 21.1344 18.4643 22.3018C18.7199 23.4691 19.3196 24.533 20.186 25.3561C20.6855 25.8616 21.179 26.4931 21.5615 27.9361C21.9095 29.2546 22.1615 31.2421 22.217 34.4011C22.7375 34.4311 23.285 34.4431 23.717 34.4491H23.7185Z"
                      fill="#FFAD55"
                    />
                  </g>
                  <g filter="url(#filter2_f_970_2067)">
                    <path
                      d="M25.8185 34.1085C25.8185 26.952 26.915 25.809 28.091 24.6225C28.851 23.9038 29.3778 22.9735 29.6032 21.9521C29.8287 20.9307 29.7424 19.8651 29.3556 18.8933C28.9688 17.9214 28.2993 17.088 27.4336 16.5008C26.568 15.9136 25.5461 15.5997 24.5 15.5997C23.454 15.5997 22.4321 15.9136 21.5665 16.5008C20.7008 17.088 20.0313 17.9214 19.6445 18.8933C19.2577 19.8651 19.1714 20.9307 19.3969 21.9521C19.6223 22.9735 20.1491 23.9038 20.909 24.6225C22.085 25.809 23.1755 27.864 23.1755 34.1085"
                      stroke="url(#paint8_linear_970_2067)"
                      stroke-width="0.25"
                    />
                  </g>
                  <g filter="url(#filter3_f_970_2067)">
                    <path
                      d="M28.4455 36.2272L19.9378 37.5602C19.5 37.6288 19.2006 38.0394 19.2692 38.4773C19.3379 38.9151 19.7484 39.2145 20.1863 39.1459L28.694 37.8128C29.1319 37.7442 29.4312 37.3337 29.3626 36.8958C29.294 36.4579 28.8834 36.1586 28.4455 36.2272Z"
                      fill="#7E6693"
                    />
                  </g>
                  <g filter="url(#filter4_f_970_2067)">
                    <path
                      d="M28.4455 39.7837L19.9378 41.1167C19.5 41.1853 19.2006 41.5959 19.2692 42.0338C19.3379 42.4717 19.7484 42.771 20.1863 42.7024L28.694 41.3694C29.1319 41.3008 29.4312 40.8902 29.3626 40.4523C29.294 40.0144 28.8834 39.7151 28.4455 39.7837Z"
                      fill="#7E6693"
                    />
                  </g>
                  <path
                    d="M29.5406 39.3905L18.8441 41.0665C18.3931 41.1372 18.0848 41.56 18.1555 42.011C18.2261 42.462 18.649 42.7703 19.0999 42.6996L29.7964 41.0236C30.2474 40.953 30.5557 40.5301 30.485 40.0791C30.4144 39.6282 29.9915 39.3199 29.5406 39.3905Z"
                    fill="url(#paint9_linear_970_2067)"
                  />
                  <path
                    d="M29.5406 39.3905L18.8441 41.0665C18.3931 41.1372 18.0848 41.56 18.1555 42.011C18.2261 42.462 18.649 42.7703 19.0999 42.6996L29.7964 41.0236C30.2474 40.953 30.5557 40.5301 30.485 40.0791C30.4144 39.6282 29.9915 39.3199 29.5406 39.3905Z"
                    fill="url(#paint10_linear_970_2067)"
                  />
                  <path
                    d="M29.5406 39.3905L18.8441 41.0665C18.3931 41.1372 18.0848 41.56 18.1555 42.011C18.2261 42.462 18.649 42.7703 19.0999 42.6996L29.7964 41.0236C30.2474 40.953 30.5557 40.5301 30.485 40.0791C30.4144 39.6282 29.9915 39.3199 29.5406 39.3905Z"
                    fill="url(#paint11_linear_970_2067)"
                  />
                  <path
                    d="M29.5406 39.3905L18.8441 41.0665C18.3931 41.1372 18.0848 41.56 18.1555 42.011C18.2261 42.462 18.649 42.7703 19.0999 42.6996L29.7964 41.0236C30.2474 40.953 30.5557 40.5301 30.485 40.0791C30.4144 39.6282 29.9915 39.3199 29.5406 39.3905Z"
                    fill="url(#paint12_radial_970_2067)"
                  />
                  <path
                    d="M29.5406 35.8251L18.8441 37.5011C18.3931 37.5717 18.0848 37.9946 18.1555 38.4456C18.2261 38.8965 18.649 39.2048 19.0999 39.1342L29.7964 37.4582C30.2474 37.3875 30.5557 36.9647 30.485 36.5137C30.4144 36.0627 29.9915 35.7544 29.5406 35.8251Z"
                    fill="url(#paint13_linear_970_2067)"
                  />
                  <path
                    d="M29.5406 35.8251L18.8441 37.5011C18.3931 37.5717 18.0848 37.9946 18.1555 38.4456C18.2261 38.8965 18.649 39.2048 19.0999 39.1342L29.7964 37.4582C30.2474 37.3875 30.5557 36.9647 30.485 36.5137C30.4144 36.0627 29.9915 35.7544 29.5406 35.8251Z"
                    fill="url(#paint14_linear_970_2067)"
                  />
                  <path
                    d="M29.5406 35.8251L18.8441 37.5011C18.3931 37.5717 18.0848 37.9946 18.1555 38.4456C18.2261 38.8965 18.649 39.2048 19.0999 39.1342L29.7964 37.4582C30.2474 37.3875 30.5557 36.9647 30.485 36.5137C30.4144 36.0627 29.9915 35.7544 29.5406 35.8251Z"
                    fill="url(#paint15_linear_970_2067)"
                  />
                  <path
                    d="M29.5406 35.8251L18.8441 37.5011C18.3931 37.5717 18.0848 37.9946 18.1555 38.4456C18.2261 38.8965 18.649 39.2048 19.0999 39.1342L29.7964 37.4582C30.2474 37.3875 30.5557 36.9647 30.485 36.5137C30.4144 36.0627 29.9915 35.7544 29.5406 35.8251Z"
                    fill="url(#paint16_radial_970_2067)"
                  />
                  <defs>
                    <filter
                      id="filter0_i_970_2067"
                      x="11.3864"
                      y="2.98914"
                      width="26.194"
                      height="31.6144"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.25" dy="0.15" />
                      <feGaussianBlur stdDeviation="0.5" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.992157 0 0 0 0 0.666667 0 0 0 0 0.309804 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_970_2067"
                      />
                    </filter>
                    <filter
                      id="filter1_i_970_2067"
                      x="18.326"
                      y="15.0475"
                      width="12.202"
                      height="19.4016"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.25" />
                      <feGaussianBlur stdDeviation="0.25" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.572549 0 0 0 0 0.254902 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_970_2067"
                      />
                    </filter>
                    <filter
                      id="filter2_f_970_2067"
                      x="18.899"
                      y="15.2247"
                      width="11.202"
                      height="19.1338"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="0.125"
                        result="effect1_foregroundBlur_970_2067"
                      />
                    </filter>
                    <filter
                      id="filter3_f_970_2067"
                      x="19.0095"
                      y="35.9674"
                      width="10.6129"
                      height="3.43823"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="0.125"
                        result="effect1_foregroundBlur_970_2067"
                      />
                    </filter>
                    <filter
                      id="filter4_f_970_2067"
                      x="19.0095"
                      y="39.5239"
                      width="10.6129"
                      height="3.43823"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="0.125"
                        result="effect1_foregroundBlur_970_2067"
                      />
                    </filter>
                    <radialGradient
                      id="paint0_radial_970_2067"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(25.0055 43.665) rotate(96.6541) scale(1.41579 3.27921)"
                    >
                      <stop offset="0.268" stop-color="#AC90C6" />
                      <stop offset="1" stop-color="#8E6AAA" />
                    </radialGradient>
                    <linearGradient
                      id="paint1_linear_970_2067"
                      x1="29.4455"
                      y1="38.7029"
                      x2="19.274"
                      y2="38.7029"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDC2D1" />
                      <stop offset="1" stop-color="#947C8D" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_970_2067"
                      x1="18.7715"
                      y1="39.4694"
                      x2="21.2555"
                      y2="39.4694"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8A7B88" />
                      <stop offset="1" stop-color="#8A7B88" stop-opacity="0" />
                    </linearGradient>
                    <radialGradient
                      id="paint3_radial_970_2067"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(26.4575 33.9599) rotate(88.6357) scale(1.23731 5.50863)"
                    >
                      <stop offset="0.229" stop-color="#EDE2EB" />
                      <stop offset="1" stop-color="#C8BCCB" stop-opacity="0" />
                    </radialGradient>
                    <linearGradient
                      id="paint4_linear_970_2067"
                      x1="26.693"
                      y1="43.3364"
                      x2="26.693"
                      y2="41.4374"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#7E5F9E" />
                      <stop offset="1" stop-color="#7E5F9E" stop-opacity="0" />
                    </linearGradient>
                    <radialGradient
                      id="paint5_radial_970_2067"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(29.8339 12.1891) rotate(138.066) scale(14.8705 12.2621)"
                    >
                      <stop offset="0.286" stop-color="#FFEC87" />
                      <stop offset="1" stop-color="#FFCD4B" />
                    </radialGradient>
                    <radialGradient
                      id="paint6_radial_970_2067"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(31.2395 20.157) rotate(-147.604) scale(21.8728 21.0387)"
                    >
                      <stop
                        offset="0.622"
                        stop-color="#FFB239"
                        stop-opacity="0"
                      />
                      <stop offset="1" stop-color="#FFB239" />
                    </radialGradient>
                    <linearGradient
                      id="paint7_linear_970_2067"
                      x1="24.947"
                      y1="12.0405"
                      x2="30.0801"
                      y2="8.0415"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" stop-opacity="0" />
                      <stop offset="0.594" stop-color="white" />
                    </linearGradient>
                    <linearGradient
                      id="paint8_linear_970_2067"
                      x1="30.08"
                      y1="16.4955"
                      x2="24.071"
                      y2="30.81"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFBF5C" />
                      <stop offset="1" stop-color="#FFBF5C" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint9_linear_970_2067"
                      x1="24.0447"
                      y1="40.2653"
                      x2="24.4439"
                      y2="42.0475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#B1969A" />
                      <stop offset="1" stop-color="#DAD2E2" />
                    </linearGradient>
                    <linearGradient
                      id="paint10_linear_970_2067"
                      x1="25.7321"
                      y1="41.6255"
                      x2="25.5455"
                      y2="40.4538"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8A68A9" />
                      <stop offset="1" stop-color="#DEC2E3" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint11_linear_970_2067"
                      x1="18.027"
                      y1="42.1404"
                      x2="22.1227"
                      y2="41.274"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#958387" />
                      <stop offset="1" stop-color="#958387" stop-opacity="0" />
                    </linearGradient>
                    <radialGradient
                      id="paint12_radial_970_2067"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(28.3329 40.1522) rotate(81.4165) scale(1.25627 7.43566)"
                    >
                      <stop offset="0.127" stop-color="#FCF0FC" />
                      <stop offset="1" stop-color="#E5C9E3" stop-opacity="0" />
                    </radialGradient>
                    <linearGradient
                      id="paint13_linear_970_2067"
                      x1="24.045"
                      y1="36.7014"
                      x2="24.4439"
                      y2="38.4821"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#B1969A" />
                      <stop offset="1" stop-color="#DAD2E2" />
                    </linearGradient>
                    <linearGradient
                      id="paint14_linear_970_2067"
                      x1="25.7321"
                      y1="38.0601"
                      x2="25.5455"
                      y2="36.8883"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8A68A9" />
                      <stop offset="1" stop-color="#DEC2E3" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint15_linear_970_2067"
                      x1="18.0272"
                      y1="38.5765"
                      x2="22.1227"
                      y2="37.7086"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#958387" />
                      <stop offset="1" stop-color="#958387" stop-opacity="0" />
                    </linearGradient>
                    <radialGradient
                      id="paint16_radial_970_2067"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(28.3329 36.5867) rotate(81.4165) scale(1.25627 7.43563)"
                    >
                      <stop offset="0.127" stop-color="#FCF0FC" />
                      <stop offset="1" stop-color="#E5C9E3" stop-opacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
                <div className="title">How can we improve CloseCarbon?</div>
                <div className="subtitle">
                  Your ideas will be sent to
                  <br /> support@closecarbon.com
                </div>
                <div className="help_link pointer" onClick={handleSuggestion}>
                  Suggest an Idea
                </div>
              </div>
            )}
            {modalOpen && (
              <div
                className="help_modal_cover"
                onClick={handleModalClose}
              ></div>
            )}
          </div>
        )}
        
        {auth.token && 
 auth?.userInfo?.role === "user" && 
 !isPathExcluded(location.pathname) && 
 <Kyc />}

      {/* </> : <NetworkError />} */}
    </div>
  );
}

export default App;
